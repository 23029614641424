import {
  mapActions, mapState,
} from 'vuex';

import {
  Sidebar,
} from '@/components';
import isServer from '../../utils/isServer';

// eslint-disable-next-line no-undef
if (!isServer) {
  const { required, email } = require('vee-validate/dist/rules');
  const { extend } = require('vee-validate');

  extend('required', {
    ...required,
    message: 'Dit veld is vereist.',
  });

  extend('email', {
    ...email,
    message: 'Een geldig e-mailadres is vereist.',
  });
}

export default {
  metaInfo: {
    title: 'Login page',
  },
  name: 'Register',
  components: {
    [Sidebar.name]: Sidebar,
  },
  props: {},
  data: () => ({
    email: '',
    password: '',
    showForgotPassword: false,
  }),
  computed: {
    ...mapState('account', {
      accountStatus: 'status',
    }),

  },
  methods: {
    ...mapActions('account', {
      loginAction: 'LOGIN',
      forgotPasswordAction: 'FORGOT_PASSWORD',
    }),
    login() {
      const userObj = {
        username: this.email,
        password: this.password,
      };

      this.loginAction(userObj)
        .then(() => {
          this.$router.push({ path: this.$route.query.redirect || '/' });
        })
        .catch((error) => this.$toasted.show(...error));
    },
    toggleForgotPassword() {
      this.showForgotPassword = !this.showForgotPassword;
    },
    forgotPassword() {
      const emailObj = { email: this.email };
      this.forgotPasswordAction(emailObj)
        .then(() => {
          this.$toasted.show(`Een e-mail om het wachtwoord te wijzigen is verstuurd naar ${this.email}.`, { type: 'success' });
        }).catch((error) => {
          this.$toasted.show(...error.response.data);
        });
    },
  },
};
